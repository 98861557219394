import {createRouter, createWebHistory} from "vue-router";
import Home from "../views/Home.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        redirect: "/dashboard",
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                meta: {
                    title: '首页'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../views/Dashboard.vue")
            }, {
                path: "/index",
                name: "Index",
                meta: {
                    title: '首页',
                    needLogin:true
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../views/Index.vue")
            }, {
                path: "/role",
                name: "Role",
                meta: {
                    title: '角色管理',
                    needLogin:true
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/sys/Role.vue")
            }, {
                path: "/admin",
                name: "Admin",
                meta: {
                    title: '系统用户',
                    needLogin:true
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/sys/Admin.vue")
            }, {
                path: "/clerk",
                name: "Clerk",
                meta: {
                    title: '终身学习导师管理',
                    needLogin:true
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/user/Clerk.vue")
            }, {
                path: "/clerk2",
                name: "Clerk2",
                meta: {
                    title: '终身学习导师管理',
                    needLogin:true
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/user/Clerk2.vue")
            },{
                path: "/parents",
                name: "Parents",
                meta: {
                    title: '智慧父母管理',
                    needLogin:true
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/user/Parents.vue")
            },{
                path: "/parents2",
                name: "Parents2",
                meta: {
                    title: '智慧父母管理',
                    needLogin:true
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/user/Parents2.vue")
            }, {
                path: "/org",
                name: "Org",
                meta: {
                    title: '机构管理',
                    needLogin:true
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/org/Org.vue")
            }, {
                path: "/activity",
                name: "Activity",
                meta: {
                    title: '活动管理',
                    needLogin:true
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/activity/Activity.vue")
            }, {
                path: "/exam",
                name: "Exam",
                meta: {
                    title: '题库管理',
                    needLogin:true
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/exam/Exam.vue")
            }, {
                path: "/examType",
                name: "ExamType",
                meta: {
                    title: '真题类型',
                    needLogin:true
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/exam/ExamType.vue")
            }, {
                path: "/credits",
                name: "Credits",
                meta: {
                    title: '积分管理',
                    needLogin:true
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/credits/Credits.vue")
            }, {
                path: "/msg",
                name: "Msg",
                meta: {
                    title: '消息管理',
                    needLogin:true
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/msg/Msg.vue")
            }, {
                path: "/txt",
                name: "Txt",
                meta: {
                    title: '关于我们',
                    needLogin:true
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/txt/Txt.vue")
            }, {
                path: "/feedBack",
                name: "FeedBack",
                meta: {
                    title: '用户反馈',
                    needLogin:true
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/feedback/FeedBack.vue")
            }, {
                path: "/creditsShare",
                name: "CreditsShare",
                meta: {
                    title: '积分设置',
                    needLogin:true
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/sys/CreditsShare.vue")
            }, {
                path: "/emailConfig",
                name: "EmailConfig",
                meta: {
                    title: '邮箱设置',
                    needLogin:true
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/sys/EmailConfig.vue")
            }, {
                path: "/banner",
                name: "Banner",
                meta: {
                    title: '轮播图管理',
                    needLogin:true
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/txt/Banner.vue")
            }, {
                path: "/news",
                name: "News",
                meta: {
                    title: '资讯管理',
                    needLogin:true
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/txt/News.vue")
            },
        ]
    }, {
        path: "/login",
        name: "Login",
        meta: {
            title: '登录'
        },
        component: () => import (
            /* webpackChunkName: "login" */
            "../views/Login.vue")
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(function(to, from, next) {
    if (!localStorage.getItem("ms_username")) {
        if (to.path !== '/login') {
            return next('/login')
        }
    }
    next()
})

export default router;
