<template>
  <div class="sidebar">
    <el-menu
        class="sidebar-el-menu"
        :default-active="onRoutes"
        :collapse="collapse"
        background-color="#324157"
        text-color="#bfcbd9"
        active-text-color="#20a0ff"
        unique-opened
        router
    >
      <template v-for="(item,index) in menus" :key="index">
        <template v-if="item.subs.length>1">
          <el-submenu :index="item.index1" :key="item.index1">
            <template #title>
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.subs">
              <el-submenu
                  v-if="subItem.subs"
                  :index="subItem.index2"
                  :key="subItem.index2"
              >
                <template #title>{{ subItem.title2 }}</template>
                <el-menu-item
                    v-for="(threeItem, i) in subItem.subs"
                    :key="i"
                    :index="threeItem.index2"
                >{{ threeItem.title2 }}
                </el-menu-item>
              </el-submenu>
              <el-menu-item
                  v-else
                  :index="subItem.index2"
                  :key="subItem.index2"
              >{{ subItem.title2 }}
              </el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index1" :key="item.index1">
            <i :class="item.icon"></i>
            <template #title>{{ item.title }}</template>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
// import bus from "../common/bus";
export default {
  data() {
    return {
      permissionIds: [],

      menus: []
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace("/", "");
    },
    collapse() {
      return this.$store.state.collapse
    }
  },
  mounted() {
    this.$axios({
      method: "get",
      url: "/permission/getList",
    }).then(res => {
      let roleId = localStorage.getItem("roleId")
      this.$axios({
        method: "get",
        url: "/rolePermission/get",
        params: {"roleId": roleId}
      }).then(response => {
        this.permissionIds = response.data.datas.permissionIds
        res.data.datas.permissionList.map((item) => {
          this.permissionIds.map((item1) => {
            if (item.permissionId === item1) {
              this.menus.push(item)
            }
          })
        })
      })
    }).catch(res => {
      console.log(res)
      if(res){
        localStorage.clear()
        this.$router.push('/login')
      }
    })

  },
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}

.sidebar > ul {
  height: 100%;
}
</style>
